import React from "react";
import logotext from "../img/catLogoText.png";
import logoimg from "../img/catLogoImg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faPaw } from "@fortawesome/free-solid-svg-icons";

class Navbar extends React.Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top">
          <a href="/" className="navbar-brand">
            <img src={logoimg} height="42" alt=""></img>
            <img src={logotext} height="42" alt=""></img>
          </a>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarResponsive"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto nav-fill">
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <a className="nav-link" href="#home">
                  <FontAwesomeIcon icon={faHome} />
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <a className="nav-link" href="#contact">
                  <FontAwesomeIcon icon={faPaw} />
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <a className="nav-link" href="#aim">
                  A.I.M
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <a className="nav-link" href="#robotics">
                  Robotics
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <a className="nav-link" href="#systems">
                  Systems
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <a className="nav-link" href="#make">
                  Make
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
