import React from "react";
import cells from "../img/rbt_cells.png";
import robots from "../img/rbt_robots.png";
import grips from "../img/rbt_grips.png";

class Aims extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="card-group">
          <div className="card">
            <img src={robots} className="card-img-top" alt="..."></img>
            <div className="card-body">
              <h5 className="card-title">Robots</h5>
              <p className="card-text">
                Backbone of sensors, actuators and controllers designed to
                perform reflexively to execute tasks that demand performance and
                precision
              </p>
            </div>
          </div>
          <div className="card">
            <img src={grips} className="card-img-top" alt="..."></img>
            <div className="card-body">
              <h5 className="card-title">Robot Cells & Tools</h5>
              <p className="card-text">
                Mechanisms and mechanical components designed to meet high
                degrees of precision that allows greater task performance and
                repeatibility
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Aims;
