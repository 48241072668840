import React from "react";
import Aims from "./Aims";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class Aim extends React.Component {
  render() {
    return (
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="display-7">A.I.M</h1>
          <p className="lead">
            Mechanical and Electrical domain integrated with Intelligence
          </p>
          <Router></Router>
          <Aims></Aims>
        </div>
      </div>
    );
  }
}

export default Aim;
