import React from "react";

class Callout extends React.Component {
  render() {
    return (
      <section className="py-3">
        <div className="container">
          <h1 className="display-5">Cloud of Talent</h1>
          <p className="lead">Engineers | Coders | Designers | Makers | ..</p>
        </div>
      </section>
    );
  }
}

export default Callout;
