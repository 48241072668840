import React from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

class Mapit extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: 36.1999,
      lng: 29.6396,
      zoom: 12,
    };
  }

  render() {
    const mapStyles = {
      position: "relative",
      width: "100%",
      height: "100%",
    };
    return (
      <div>
        <Map
          google={this.props.google}
          zoom={this.state.zoom}
          style={mapStyles}
          initialCenter={{ lat: this.state.lat, lng: this.state.lng }}
        />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCHoKlYDRI29Kiy49UN6-NoFROs4w7xJZQ",
})(Mapit);
