import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCwHiRziJwA3GOsrrIRqby0AlVlQi53Ukc",
  authDomain: "icatinvent.firebaseapp.com",
  databaseURL: "https://icatinvent.firebaseio.com",
  projectId: "icatinvent",
  storageBucket: "icatinvent.appspot.com",
  messagingSenderId: "871983218871",
  appId: "1:871983218871:web:67aa490d7e52b90d9ecd32",
  measurementId: "G-51FLWEDHVS",
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

async function newVisit() {
  analytics.logEvent("new_visit");
  console.log("new visitor");
}

export default newVisit;
