import React from "react";
import design from "../img/make_design.png";
import prototype from "../img/make_prot.png";
import opensrc from "../img/make_orsc.png";

class Aims extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="card-group">
          <div className="card">
            <img src={design} className="card-img-top" alt="..."></img>
            <div className="card-body">
              <h5 className="card-title">Design</h5>
              <p className="card-text">
                Backbone of sensors, actuators and controllers designed to
                perform reflexively to execute tasks that demand performance and
                precision
              </p>
            </div>
          </div>
          <div className="card">
            <img src={prototype} className="card-img-top" alt="..."></img>
            <div className="card-body">
              <h5 className="card-title">Prototype</h5>
              <p className="card-text">
                Software implementations to bridge the gap between the
                mechanical physics and electrical sensing and actuation with AI
                support
              </p>
            </div>
          </div>
          <div className="card">
            <img src={opensrc} className="card-img-top" alt="..."></img>
            <div className="card-body">
              <h5 className="card-title">Open-Source</h5>
              <p className="card-text">
                Mechanisms and mechanical components designed to meet high
                degrees of precision that allows greater task performance and
                repeatibility
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Aims;
