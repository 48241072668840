import React from "react";
import Mapit from "./Mapit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";

class Contact extends React.Component {
  render() {
    return (
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="display-7">
            paw.us.
            <FontAwesomeIcon icon={faPaw} />
          </h1>

          <section className="mb-4">
            <p className="text-left w-responsive mx-auto mb-5">
              Do you have any questions? Please do not hesitate to contact us
              directly. Our team will come back to you within a matter of hours
              to help you.
            </p>

            <div className="row">
              <div className="col-md-6 mb-md-0 mb-5">
                <form
                  id="contact-form"
                  name="contact-form"
                  action="mail.php"
                  method="POST"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="md-form mb-0">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                        />
                        <label htmlFor="name" className="">
                          Your name
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="md-form mb-0">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="form-control"
                        />
                        <label htmlFor="email" className="">
                          Your email
                        </label>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="text-center text-md-left">
                  <a className="btn btn-primary" href="/">
                    Send
                  </a>
                </div>
                <div className="status"></div>
              </div>

              <div className="col-md-5 text-center">
                <ul className="list-unstyled mb-md-0 mb-5">
                  <Mapit></Mapit>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Contact;
