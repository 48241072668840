import React from "react";
import coke from "../img/sys_coke.png";
import merc from "../img/sys_merc.png";

class Sys extends React.Component {
  render() {
    return (
      <div className="card mb-3">
        <div className="row no-gutters">
          <div className="col-md-6">
            <div className="card-body">
              <h5 className="card-title">Logistics</h5>
              <p className="card-text">
                This is a wider card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
              <p className="card-text">
                <small className="text-muted">Last updated 3 mins ago</small>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img src={coke} className="card-img" alt="..."></img>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-md-6">
            <img src={merc} className="card-img-top" alt="..."></img>
          </div>
          <div className="col-md-6">
            <div className="card-body">
              <h5 className="card-title">Materials Handling</h5>
              <p className="card-text">
                This is a wider card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
              <p className="card-text">
                <small className="text-muted">Last updated 3 mins ago</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sys;
