import React from "react";
import vid1 from "../img/kawasaki.mp4";
import aim from "../img/crsl_aim.png";
import robot from "../img/crsl_robots.png";
import system from "../img/crsl_system.png";
import make from "../img/crsl_make.png";

class Slider extends React.Component {
  render() {
    return (
      <div
        id="crl"
        className="carousel slide"
        data-ride="carousel"
        data-interval="4000"
      >
        {/* BOTTOM INDICATORS */}
        <ol className="carousel-indicators">
          <li data-target="#crl" data-slide-to="0" className="active"></li>
          <li data-target="#crl" data-slide-to="1"></li>
          <li data-target="#crl" data-slide-to="2"></li>
          <li data-target="#crl" data-slide-to="3"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
          {/* SLIDE 1 */}
          <div
            className="carousel-item active"
            style={{ backgroundImage: `url(${aim})` }}
          >
            <div className="carousel-caption d-block d-md-block">
              <h1>A.I.M</h1>
              <p>Automation | Intelligence | Mechanics</p>
            </div>
          </div>
          {/* SLIDE 2 */}
          <div
            className="carousel-item"
            style={{ backgroundImage: `url(${robot})` }}
          >
            <div className="carousel-caption d-block d-md-block">
              <h1>Robotics</h1>
              <p>Robots | Workcells | Tooling</p>
            </div>
          </div>
          {/* SLIDE 3 */}
          <div
            className="carousel-item"
            style={{ backgroundImage: `url(${system})` }}
          >
            <div className="carousel-caption d-block d-md-block">
              <h1>Systems</h1>
              <p>Logistics | Materials Handling | Manufacturing</p>
            </div>
          </div>
          {/* SLIDE 4 */}
          <div
            className="carousel-item"
            style={{ backgroundImage: `url(${make})` }}
          >
            <div className="carousel-caption text-center">
              <h1>Make</h1>
              <p>Design | Prototyping | Open-Source</p>
            </div>
          </div>
        </div>
        {/* PREV BUTTON */}
        <a
          className="carousel-control-prev"
          href="#crl"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        {/* NEXT BUTTON */}
        <a
          className="carousel-control-next"
          href="#crl"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

export default Slider;
