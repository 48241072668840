/* STANDARD React Stuff */
import React from "react";
import "./App.css";

/* CUSTOM Implementations */
import Navbar from "./component/Navbar";
import Slider from "./component/Slider";
import Callout from "./component/Callout";
import Aim from "./component/Aim";
import Robotics from "./component/Robotics";
import Systems from "./component/Systems";
import Make from "./component/Make";
import Contact from "./component/Contact";
import Firebase from "./firebase/Firebase_me";

function App() {
  Firebase();
  return (
    <div className="App">
      <Navbar></Navbar>
      <header id="home">
        <Slider></Slider>
        <Callout></Callout>
      </header>
      <section>
        <div id="aim">
          <Aim></Aim>
        </div>
        <div id="robotics">
          <Robotics></Robotics>
        </div>
        <div id="systems">
          <Systems></Systems>
        </div>
        <div id="make">
          <Make></Make>
        </div>
        <div id="contact">
          <Contact></Contact>
        </div>
      </section>
    </div>
  );
}

export default App;
