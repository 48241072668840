import React from "react";
import auto from "../img/aim_autom.png";
import intel from "../img/aim_intel.png";
import mecha from "../img/aim_mecha.png";

class Aims extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="card-group">
          <div className="card">
            <img src={auto} className="card-img-top" alt="..."></img>
            <div className="card-body">
              <h5 className="card-title">Automation</h5>
              <p className="card-text">
                Backbone of sensors, actuators and controllers designed to
                perform reflexively to execute tasks that demand performance and
                precision
              </p>
            </div>
          </div>
          <div className="card">
            <img src={intel} className="card-img-top" alt="..."></img>
            <div className="card-body">
              <h5 className="card-title">Intelligence</h5>
              <p className="card-text">
                Software implementations to bridge the gap between the
                mechanical physics and electrical sensing and actuation with AI
                support
              </p>
            </div>
          </div>
          <div className="card">
            <img src={mecha} className="card-img-top" alt="..."></img>
            <div className="card-body">
              <h5 className="card-title">Mechanics</h5>
              <p className="card-text">
                Mechanisms and mechanical components designed to meet high
                degrees of precision that allows greater task performance and
                repeatibility
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Aims;
