import React from "react";
import Sys from "./Sys";

class Systems extends React.Component {
  render() {
    return (
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="display-7">Systems</h1>
          <p className="lead">Logistics | Materials Handling | Manufacturing</p>
          <Sys></Sys>
        </div>
      </div>
    );
  }
}

export default Systems;
